export const DASHBOARD = "/";
export const USERS = "/users";
export const USER_ROLES = "/users/roles";
export const USER_TEAM = "/users/teams";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const LEAD = "/leads";
export const LEAD_BULK_UPLOAD = "/lead/upload";
export const LEAD_CALL_FEED = "/lead/callings";
export const TODAY_LEAD = "/lead/today";
export const ASSETS = "/assets-websites";
export const WIDGET_DETAIL = "/widgets/:id";
export const WIDGETS = "/widgets";
export const SUPPORT = "/support";
export const SETTINGS = "/settings";
export const MASTER_DATA = "/master-data";
export const FAQ = "/faq";
export const RESET_PASSWORD = "/reset-password";
export const SOCIAL_APP = "/social-app";
