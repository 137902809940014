import * as routePaths from "./RouteConstant";
import { Navigate, RouteObject } from "react-router-dom";
import React, { Suspense, useContext } from "react";
import { AppContext } from "./App";
import { Layout } from "./shared/Layout";

const PageNotFound = React.lazy(() =>
  import("./pages/NotFound").then((module) => ({
    default: module.PageNotFound,
  })),
);
const Login = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Login,
  })),
);

const AdminDashboard = React.lazy(() =>
  import("./pages/AdminDashboard").then((module) => ({
    default: module.AdminDashboard,
  })),
);
const ManageTeamPage = React.lazy(() =>
  import("./pages/UserManagement").then((module) => ({
    default: module.ManageTeamPage,
  })),
);
const ManageUserPage = React.lazy(() =>
  import("./pages/UserManagement").then((module) => ({
    default: module.ManageUserPage,
  })),
);
const RolesPage = React.lazy(() =>
  import("./pages/UserManagement").then((module) => ({
    default: module.RolesPage,
  })),
);
const ResetEmailPage = React.lazy(() =>
  import("./pages/ForgotPassword").then((module) => ({
    default: module.ResetEmailPage,
  })),
);
const ChangePasswordPage = React.lazy(() =>
  import("./pages/ForgotPassword").then((module) => ({
    default: module.ChangePasswordPage,
  })),
);
const LeadManagementPage = React.lazy(() =>
  import("./pages/LeadManagement").then((module) => ({
    default: module.ManageLeadPage,
  })),
);
const LeadDashboardPage = React.lazy(() =>
  import("./pages/LeadManagement").then((module) => ({
    default: module.LeadDashboardPage,
  })),
);
const TodayLeadPage = React.lazy(() =>
  import("./pages/LeadManagement").then((module) => ({
    default: module.TodayLeadPage,
  })),
);
const WidgetListPage = React.lazy(() =>
  import("./pages/Widgets").then((module) => ({
    default: module.WidgetListPage,
  })),
);
const WidgetDetailPage = React.lazy(() =>
  import("./pages/Widgets").then((module) => ({
    default: module.WidgetDetailPage,
  })),
);
const SocialAppPage = React.lazy(() =>
  import("./pages/SocialApp").then((module) => ({
    default: module.SocialAppPage,
  })),
);
const SupportPage = React.lazy(() =>
  import("./pages/Support").then((module) => ({
    default: module.SupportPage,
  })),
);
const MasterDataPage = React.lazy(() =>
  import("./pages/MasterData").then((module) => ({
    default: module.MasterDataPage,
  })),
);

const AuthRoutes = ({ component }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoggedIn = !!state?.user;
  const isLoading = state?.app_loading;
  if (!isLoggedIn) {
    return <Navigate to={routePaths.LOGIN} />;
  }
  return (
    <>
      {!isLoading ? (
        <Layout>
          <Suspense fallback={"Loading"}>{component}</Suspense>
        </Layout>
      ) : null}
    </>
  );
};

const PublicRoutes = ({ component }: any) => {
  return <Suspense fallback={"Loading"}>{component}</Suspense>;
};
export const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: routePaths.DASHBOARD,
        element: <AuthRoutes component={<AdminDashboard />} />,
      },
      {
        path: routePaths.USERS,
        element: <AuthRoutes component={<ManageUserPage />} />,
      },
      {
        path: routePaths.USER_ROLES,
        element: <AuthRoutes component={<RolesPage />} />,
      },
      {
        path: routePaths.USER_TEAM,
        element: <AuthRoutes component={<ManageTeamPage />} />,
      },
      {
        path: routePaths.LEAD,
        element: <AuthRoutes component={<LeadManagementPage />} />,
      },
      {
        path: routePaths.LEAD_CALL_FEED,
        element: <AuthRoutes component={<LeadDashboardPage />} />,
      },
      {
        path: routePaths.TODAY_LEAD,
        element: <AuthRoutes component={<TodayLeadPage />} />,
      },
      {
        path: routePaths.MASTER_DATA,
        element: <AuthRoutes component={<MasterDataPage />} />,
      },
      {
        path: routePaths.WIDGETS,
        element: <AuthRoutes component={<WidgetListPage />} />,
      },
      {
        path: routePaths.SOCIAL_APP,
        element: <AuthRoutes component={<SocialAppPage />} />,
      },
      {
        path: routePaths.WIDGET_DETAIL,
        element: <AuthRoutes component={<WidgetDetailPage />} />,
      },
      {
        path: routePaths.SETTINGS,
        element: <AuthRoutes component={<MasterDataPage />} />,
      },
      {
        path: routePaths.SUPPORT,
        element: <AuthRoutes component={<SupportPage />} />,
      },
      {
        path: routePaths.LOGIN,
        element: <PublicRoutes component={<Login />} />,
      },
      {
        path: routePaths.FORGOT_PASSWORD,
        element: <PublicRoutes component={<ResetEmailPage />} />,
      },
      {
        path: routePaths.RESET_PASSWORD,
        element: <PublicRoutes component={<ChangePasswordPage />} />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={"Loading"}>
        <PageNotFound />
      </Suspense>
    ),
  },
];
