import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Icon } from "@lipihipi/lms-ui-components";
import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";

interface ISidebar {
  toggle: boolean;
  setToggle: Function;
  logoUrl: string;
  menuArr: any[];
}

export const Sidebar: FC<ISidebar> = ({
  toggle,
  setToggle,
  logoUrl,
  menuArr,
}) => {
  const [open, setOpen] = React.useState("");
  const location = useLocation();
  const classes = {
    root: {
      backgroundColor: "#fff",
      flexShrink: 0,
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      // transition: 'all ease-in-out .3s',
    },

    header: {
      flexShrink: 0,
      height: "4.5rem",
      padding: "0 0.75rem",
      display: "flex",
      alignItems: "center",
      "& img": {
        height: "38px",
      },
    },

    body: {
      overflow: "auto",
      flexGrow: 1,
    },

    footer: {
      flexShrink: 0,
      padding: "0.5rem 1.5rem",
    },
  };

  return (
    <Box
      sx={{
        ...classes.root,
        width: toggle ? "16.875rem" : "4rem",
      }}
    >
      <Box sx={classes.header}>
        {!toggle ? (
          <IconButton onClick={() => setToggle((prev: boolean) => !prev)}>
            <Icon.NavIcon />
          </IconButton>
        ) : (
          <img src={logoUrl} alt="logo" />
        )}
      </Box>
      <Box sx={classes.body}>
        {menuArr?.map((menu: any) => (
          <Box key={menu?.id}>
            <List
              component="nav"
              subheader={
                toggle ? (
                  <Typography
                    textTransform="uppercase"
                    variant="overline"
                    sx={{
                      display: "block",
                      fontWeight: 700,
                      padding: "0.75rem 1.5rem",
                      color: "#A3A5A9",
                    }}
                  >
                    {menu?.heading}
                  </Typography>
                ) : null
              }
            >
              {menu?.data?.map((item: any, idx: number) => {
                if (item?.child) {
                  return (
                    <>
                      <ListItemButton
                        selected={open?.includes(item?.id)}
                        key={`list-button-${idx + 1}`}
                        onClick={() => {
                          if (!toggle) {
                            setToggle(true);
                          }
                          setOpen(() =>
                            open?.includes(item?.id) ? "" : item?.id,
                          );
                        }}
                      >
                        <ListItemIcon>
                          <item.icon color="currentColor" />
                        </ListItemIcon>
                        {toggle && (
                          <>
                            <ListItemText primary={item?.text} />
                            <Icon.Right
                              color="currentColor"
                              style={{
                                transform: open?.includes(item?.id)
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                              }}
                            />
                          </>
                        )}
                      </ListItemButton>

                      {toggle && (
                        <Box px={3}>
                          <Collapse
                            in={open?.includes(item?.id)}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div">
                              {item?.child?.map((child: any) => (
                                <ListItemButton
                                  selected={location?.pathname === child?.link}
                                  disabled={!child.active}
                                  component={Link}
                                  key={child?.id}
                                  to={child?.link}
                                  sx={{
                                    pl: 4,
                                    borderRadius: 2,
                                    "&.Mui-selected": {
                                      backgroundColor: "#EEF3FF",
                                      "& .MuiListItemText-primary": {
                                        fontWeight: 400,
                                      },
                                      "&:before": {
                                        display: "none",
                                      },
                                      "&:hover": {
                                        backgroundColor: "#EEF3FF",
                                      },
                                    },
                                  }}
                                >
                                  <ListItemText primary={child?.text} />
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        </Box>
                      )}
                    </>
                  );
                }
                return (
                  <ListItemButton
                    component={Link}
                    to={item?.link}
                    selected={location?.pathname === item?.link}
                    disabled={!item.active}
                    key={item?.id}
                  >
                    <ListItemIcon>
                      <item.icon color="currentColor" />
                    </ListItemIcon>
                    {toggle && <ListItemText primary={item?.text} />}
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
        ))}
      </Box>
      {toggle && (
        <Box sx={classes.footer}>
          <Typography variant="caption" sx={{ display: "block" }}>
            Copyright &copy; 2024 ******.
          </Typography>
          <Typography variant="caption" sx={{ display: "block" }}>
            All rights reserved
          </Typography>
          {/* <Typography variant="caption" sx={{ display: "block" }}>
            IP address: 103.208.71.156
          </Typography> */}
        </Box>
      )}
    </Box>
  );
};
