import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import LmsAPI from "@lipihipi/lms-client-sdk";

if (process.env.REACT_APP_ENV === "prod") {
  LmsAPI.setSiteUrl("https://api.user.lipifitcrm.com/api/v1");
} else {
  LmsAPI.setSiteUrl("https://api.user.lipiguide.com/api/v1");
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

LmsAPI.config.get().then(({ data }: any) => {
  return root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App config={data} />
      </BrowserRouter>
    </React.StrictMode>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
