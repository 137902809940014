import { Modules, UserType } from "../constant";
import { DASHBOARD, LEAD, USERS } from "../RouteConstant";

export const getNavigation = (user: any) => {
  if (user?.modelPermissions && user.userType === UserType.Admin) {
    const permittedModules = Object.keys(user.modelPermissions);
    if (permittedModules.includes(Modules.Dashboard)) {
      return DASHBOARD;
    } else if (permittedModules.includes(Modules.Lead)) {
      return LEAD;
    } else if (permittedModules.includes(Modules.User)) {
      return USERS;
    } else {
      return DASHBOARD;
    }
  } else {
    return DASHBOARD;
  }
};
