import { Modules } from "../../constant";
import { Icon } from "@lipihipi/lms-ui-components";
import * as routePaths from "../../RouteConstant";

const supportMenu = {
  id: "1",
  heading: "Help Center",
  data: [
    {
      id: "0.12",
      icon: Icon.Help,
      text: "Support",
      link: "/support",
      active: false,
    },
    {
      id: "1.2",
      icon: Icon.Faq,
      text: "FAQ’s",
      link: "/faq",
      active: false,
    },
  ],
};
export const MenuArr = [
  {
    id: "0",
    heading: "Menu",
    data: [
      {
        id: "0.1",
        icon: Icon.Dashboard,
        text: "Dashboard",
        active: true,
        child: [
          {
            id: "0.1.1",
            text: "Admin Dashboard",
            link: routePaths.DASHBOARD,
            active: true,
          },
        ],
      },
      {
        id: "0.2",
        icon: Icon.Lead,
        text: "Lead Management",
        active: true,
        modules: [Modules.Lead],
        child: [
          {
            id: "0.2.1",
            text: "Leads",
            link: routePaths.LEAD,
            module: Modules.Lead,
            active: true,
          },
          {
            id: "0.2.2",
            text: "Today's Lead",
            link: routePaths.TODAY_LEAD,
            active: true,
          },
          {
            id: "0.2.3",
            text: "Live Feed",
            link: routePaths.LEAD_CALL_FEED,
            active: true,
          },
          {
            id: "0.2.4",
            text: "Bulk Upload",
            link: routePaths.LEAD_BULK_UPLOAD,
            active: false,
          },
        ],
      },
      {
        id: "0.7",
        icon: Icon.Widget,
        text: "My Widgets",
        link: routePaths.WIDGETS,
        active: true,
        modules: [Modules.Widget],
      },
      {
        id: "0.8",
        icon: Icon.User,
        text: "User & Permissions",
        active: true,
        modules: [Modules.User, Modules.Role, Modules.Team],
        child: [
          {
            id: "0.8.1",
            text: "Users Management",
            link: routePaths.USERS,
            active: true,
            module: Modules.User,
          },
          {
            id: "0.8.2",
            text: "Roles & Permissions",
            link: routePaths.USER_ROLES,
            active: true,
            module: Modules.Role,
          },
          {
            id: "0.8.3",
            text: "Manage Team",
            link: routePaths.USER_TEAM,
            active: true,
            module: Modules.Team,
          },
        ],
      },
      {
        id: "0.16",
        icon: Icon.SocialApp,
        text: "Social App Management",
        link: routePaths.SOCIAL_APP,
        active: true,
        modules: [Modules.SocialApp],
      },
      {
        id: "0.17",
        icon: Icon.Settings,
        text: "Master Data Management",
        link: routePaths.MASTER_DATA,
        active: true,
        modules: [Modules.MASTER_DATA],
      },
      {
        id: "0.19",
        icon: Icon.Support,
        text: "Support",
        link: routePaths.SUPPORT,
        active: true,
        modules: [Modules.Support],
      },
    ],
  },
  supportMenu,
];
