import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import { TemporaryDrawer, Text, Icon } from "@lipihipi/lms-ui-components";
import { Close } from "@mui/icons-material";
import { useState, type FC, useContext } from "react";
import { AppContext } from "../../App";
import { StatusButton } from "./status";

interface IHeader {
  setOpen: Function;
  open: boolean;
  logoUrl: string;
}

export function stringAvatar(name: string) {
  return {
    children: `${name.split(" ")[0][0].toLocaleUpperCase()}${
      name.split(" ")[1] ? name.split(" ")[1][0].toLocaleUpperCase() : ""
    }`,
  };
}

export const Header: FC<IHeader> = ({ setOpen, open, logoUrl }) => {
  const { state } = useContext<any>(AppContext);
  const [userName, setUserName] = useState(false);
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const classes = {
    root: {
      position: "sticky",
      top: 0,
      zIndex: 99,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "4.5rem",
      boxShadow: "0px 2px 10px 0px rgba(27, 46, 94, 0.04)",
      background: "#FFF",
      "& img": {
        height: "38px",
      },
    },
  };
  return (
    <Box pl={open ? 0 : 3} pr={3} sx={classes.root}>
      <Box height={1} display="flex" alignItems="center">
        {open ? (
          <IconButton
            sx={{
              borderRadius: 0,
              width: "auto",
              px: "1.5rem",
              height: "100%",
            }}
            onClick={() => setOpen((prev: boolean) => !prev)}
          >
            <Icon.NavIcon />
          </IconButton>
        ) : (
          <img src={logoUrl} alt="logo" />
        )}
      </Box>

      <Box display="flex" gap={2.5}>
        <Box display="flex" gap={2}>
          <StatusButton />
          <Tooltip title="Message">
            <IconButton>
              <Icon.Message />
            </IconButton>
          </Tooltip>

          <Tooltip title="Notification">
            <Badge badgeContent={4} color="primary">
              <IconButton>
                <Icon.Notification />
              </IconButton>
            </Badge>
          </Tooltip>
        </Box>

        <Box borderLeft="1px solid #E1E3E6" pl={2.5}>
          <IconButton
            onClick={() => setUserName(true)}
            sx={{
              width: "auto",
              height: "auto",
              padding: 0,
              gap: 1,
              backgroundColor: "transparent",

              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <Avatar
              sx={{
                width: "2.625rem",
                height: "2.625rem",
              }}
              alt={`${state?.user?.firstName} ${state?.user?.lastName}`}
              src={
                state?.user?.image?.url ||
                "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fpluspng.com%2Fimg-png%2Fuser-png-icon-young-user-icon-2400.png&f=1&nofb=1&ipt=64a35864790504975bf2765d2eb373c809c08a88766a1660fe561f9b0d075fbe&ipo=images"
              }
            />
            <Text
              sx={{
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "#121212",
              }}
            >
              {`${state?.user?.firstName} ${state?.user?.lastName}`}
            </Text>
            <Icon.Down />
          </IconButton>

          <TemporaryDrawer
            disableGutter
            action={false}
            onClose={() => setUserName(false)}
            open={userName}
            header={
              <Box p={3} sx={{ background: "#EFF3FF" }}>
                <Box display="flex" justifyContent="space-between">
                  <IconButton
                    onClick={() => setUserName(false)}
                    sx={{
                      p: 0,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Close sx={{ color: "#121212" }} />
                  </IconButton>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Badge
                    sx={{
                      mb: 1.5,
                      "& .MuiBadge-dot": {
                        position: "absolute",
                        top: "12px",
                        right: "12px",
                      },
                    }}
                    variant="dot"
                    color="success"
                  >
                    {state?.user?.image?.url && (
                      <Avatar
                        sx={{
                          background: "#fff",
                          width: 90,
                          fontSize: "2rem",
                          height: 90,
                          color: "#0A4DFF",
                          fontWeight: 700,
                        }}
                        src={state?.user?.image?.url}
                      />
                    )}
                    {!state?.user?.image?.url && (
                      <Avatar
                        sx={{
                          background: "#fff",
                          width: 90,
                          fontSize: "2rem",
                          height: 90,
                          color: "#0A4DFF",
                          fontWeight: 700,
                        }}
                        {...stringAvatar(
                          `${state?.user?.firstName} ${state?.user?.lastName}`,
                        )}
                      />
                    )}
                  </Badge>

                  <Text variant="h4">
                    {state?.user?.firstName} {state?.user?.lastName}
                  </Text>

                  <Box
                    flexDirection="column"
                    display="flex"
                    alignItems="center"
                    mt={1}
                    gap={1}
                  >
                    <Chip
                      icon={<PhoneIcon sx={{ fill: "currentcolor" }} />}
                      label={state?.user?.phone}
                    />
                    <Chip
                      icon={
                        <AlternateEmailIcon sx={{ fill: "currentcolor" }} />
                      }
                      label={state?.user?.email}
                    />
                  </Box>
                </Box>
              </Box>
            }
          >
            <List>
              <ListItem component={Button} onClick={logout}>
                Logout
              </ListItem>
            </List>
          </TemporaryDrawer>
        </Box>
      </Box>
    </Box>
  );
};
