export const LOGIN = "LOGIN";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const USER_MANAGEMENT = "USER_MANAGEMENT";
export const ROLES = "ROLES";
export const PERMISSION = "PERMISSION";
export const ADMINDASHBOARD = "ADMINDASHBOARD";
export const LEAD = "LEAD";
export const LEAD_DETAIL = "LEAD_DETAIL";
export const BULK_UPLOAD = "BULK_UPLOAD";
export const BUlk = "BUlk";
export const ASSETS = "ASSETS";
export const WIDGETS = "WIDGETS";
export const TEAM = "TEAM";
export const DATA = "DATA";
export const SUPPORT = "SUPPORT";
export const SETTINGS = "SETTINGS";
export const FAQ = "FAQ";

export enum Modules {
  User = "User",
  Role = "Role",
  Team = "Team",
  Widget = "Widget",
  Lead = "Lead",
  Setting = "Setting",
  MASTER_DATA = "Setting",
  Dashboard = "Dashboard",
  SocialApp = "SocialApp",
  Support = "Support",
}

export enum ModelPermission {
  Read = "Read",
  Write = "Write",
  Edit = "Edit",
  Remove = "Remove",
}

export const ModulesList = [
  {
    label: "User Management",
    value: Modules.User,
  },
  {
    label: "Dashboard",
    value: Modules.Dashboard,
  },
  {
    label: "Lead",
    value: Modules.Lead,
  },

  {
    label: "Widgets",
    value: Modules.Widget,
  },
  {
    label: "Roles and Permissions",
    value: Modules.Role,
  },
  {
    label: "Team Management",
    value: Modules.Team,
  },
  {
    label: "Settings",
    value: Modules.Setting,
  },
];
export enum UserType {
  Admin = "Admin",
  User = "User",
}

export const systemFields = [
  "name",
  "email",
  "phone",
  "addressLine1",
  "city",
  "state",
  "pincode",
  "dateOfBirth",
  "gender",
];
